import axios from "axios";
import UseAxios from "../APIs/UseAxios";
import { AuthProvider } from "../Context/AuthContext";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import logo1 from '../Assets/MundoBarato1.png'
import { Link, Navigate } from "react-router-dom";


function RegisterOne (props){

    
  const [uAccess, setUAccess] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userNick, setUserNick] = useState();
  const [userPass, setUserPass] = useState();
  const [userPassII, setUserPassII] = useState();
  const [userData, setUserData] = useState([]);
  const [kontrl, setKontrl] = useState(localStorage.getItem('kontrol'));
  const [dizpl, setDizpl] = useState("block");

  const IPRegisterUser=process.env.REACT_APP_REGISTER;

  


    const letsRegister = (a,b,c,d) =>{

      
      if(!userEmail){
            
        toast(`Por favor coloque su correo electrónico!`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else  if(userEmail.length<=6){
        console.log(userEmail.length);
        toast(`Por favor coloque su correo electrónico!`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else if(!userNick){
            
        toast(`Por favor coloque su número telefónico`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else if(!userPass){
            
        toast(`Por favor coloque una contraseña`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else if(!userPassII){
            
        toast(`Por favor coloque nuevamente su contraseña`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else if(userPass!=userPassII){
            
        toast(`Por favor ambas contraseñas deben ser iguales`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else {

        axios.post(IPRegisterUser, {email:a,username:b,password:c,password2:d},  {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            console.log(`Usuario ${a} registrado`);
            
            
         
          
            
            
            
              
              
        
              toast(`Usuario registrado, puede ingresar con sus credenciales.`, {
                draggablePercent: 20, type: "success", autoClose: 1500, });
                setKontrl(1);
                setTimeout(function() {
                  window.location.href = "/";
                  
               }, 1500);
                
            
              // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              
                
            
            }).catch(error => {            
                console.log(`Error: ${error.message}`)
                if(error.message==="Network Error"){
                    toast(`Error de conexión`, {
                        draggablePercent: 20, type: "error", autoClose: 10000, });
                }else {
  
                toast(`Error: ${error.message}`, {
                    draggablePercent: 20, type: "error", autoClose: 10000, });}
              })  
  
      }}


return (
    <>
  
  <div className="center">
              <div className="center">
              <Link to={"/"}><img src={logo1} width='25%' alt="Logo"></img></Link>
          </div>
            <div className="box" style={{width:`25%`}}>
              <input className="input" placeholder="Correo" value={userEmail} type="text" onChange={(e)=> {setUserEmail(e.target.value)}}></input>
              <input className="input" placeholder="Número Telefónico" value={userNick} type="number" onChange={(e)=> setUserNick(e.target.value)}></input>
             
              <input className="input" placeholder="Contraseña" value={userPass} type="password" onChange={(e)=> setUserPass(e.target.value)}></input>  
                   
              <div class="notification" key={dizpl} style={{display:`${dizpl}`,textAlign:`justify`}}>
                <button class="delete" onClick={(e)=>{setDizpl("none")}}></button>
                <br/>
                Para crear una contraseña segura, se recomienda:
                <ol>
                <li>Que tenga al menos 8 caracteres </li>
                <li>Que incluya mayúsculas, minúsculas, números y símbolos </li>
                <li>Que no sea una palabra sencilla, un nombre propio, un lugar, una fecha de nacimiento, ni una contraseña similar a otras </li>
                <li>Que sea difícil de adivinar o descifrar </li>
                </ol>   
              </div>
              <input className="input" placeholder="Repita la contraseña" value={userPassII} type="password" onChange={(e)=> setUserPassII(e.target.value)}></input>
              <button className="button" onClick={()=> letsRegister(userEmail,userNick,userPass,userPassII)}>Registrarse</button>
             
            </div>
            <div><em> ¿Necesita asistencia?</em> <a href={`https://wa.me/18496544656?text=Hola%20solicito%20apoyo%20para%20registrarme`}> <strong style={{color:`#0085c9`}}>Contáctenos</strong></a></div>
            <div className="section is-large"></div>
          </div>
    <ToastContainer />
    </>
)


}

export default RegisterOne;